import React from "react";
import ReactDOM from "react-dom";
import "./fonts.css";
import "./tailwind.css";
import reportWebVitals from "./reportWebVitals";
import { App } from "./app";
import { enableMapSet } from "immer";
import { firebaseConfig, APP_URL } from "./constant";
import { APP_URL as APP_URL_PROD } from "./constant/production";
enableMapSet();

const hostname = window.location.hostname;
const projectId = firebaseConfig.projectId;
if (process.env.NODE_ENV === "production" && hostname !== "localhost") {
  if (
    projectId === "mortgage-rec-engine-prod" &&
    !APP_URL_PROD.includes(hostname)
  ) {
    window.location.href = APP_URL_PROD;
  } else if (
    projectId === "mortgage-rec-engine-dev" &&
    !APP_URL.includes(hostname)
  ) {
    window.location.href = APP_URL;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
