import { FirebaseOptions } from "firebase/app";

export const USERSNAP_GLOBAL_API_KEY = "0c27c33e-7dcf-429a-9613-249a7ba43014";
export const FUNCTIONS_BASE_URL =
  "https://us-central1-mortgage-rec-engine-prod.cloudfunctions.net";
export const SPREADSHEET_IMAGE_FUNCTION_URL =
  "https://spreadsheet-to-image-service-u23irnsdnq-uc.a.run.app/getPreviewImage";
export const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyC0DTXEKEXPzyvxX67U-I64vVEZblpwd8U",
  authDomain: "mortgage-rec-engine-prod.firebaseapp.com",
  projectId: "mortgage-rec-engine-prod",
  storageBucket: "mortgage-rec-engine-prod.appspot.com",
  messagingSenderId: "766186937402",
  appId: "1:766186937402:web:6d1c36569ce41d5b406903",
};
export const APP_URL = "https://mre.blackfin.tools";
