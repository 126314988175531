import { FC, useState, useCallback, useEffect } from "react";
import shallow from "zustand/shallow";
import { useStore } from "..";
import { Result, NoResult, ResultLoading } from ".";
import { twMerge } from "tailwind-merge";

const ResultManager: FC = () => {
  const [addEventListener, removeEventsListener] = useStore(
    useCallback(
      (state) => [state.addEventListener, state.removeEventListener],
      []
    ),
    shallow
  );

  const [showResult, setShowResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const searchStart = () => {
      setShowResult(false);
      setIsLoading(true);
    };
    const searchSuccess = () => {
      setIsLoading(false);
      setShowResult(true);
    };

    addEventListener("searchstart", searchStart);
    addEventListener("searchsuccess", searchSuccess);

    return () => {
      removeEventsListener("searchstart", searchStart);
      removeEventsListener("searchsuccess", searchSuccess);
    };
  }, [addEventListener, removeEventsListener]);

  return (
    <section
      className={twMerge(
        "max-w-full rounded-[3px] p-4",
        showResult || isLoading ? "bg-white" : "bg-[#F8F7FA]"
      )}
    >
      {isLoading && <ResultLoading />}
      {!isLoading && showResult && <Result />}
      {!isLoading && !showResult && <NoResult />}
    </section>
  );
};

export default ResultManager;
