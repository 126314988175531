import { FC, MouseEventHandler, useEffect, useState } from "react";
import { useStore } from ".";
import { Button } from "./components/form";
import { useCallback } from "react";
import shallow from "zustand/shallow";

const SearchButton: FC = () => {
  const [isValid, fetchReport, addEventListener, removeEventsListener] =
    useStore(
      useCallback(
        (state) => [
          state.isValid().valid,
          state.fetchReport,
          state.addEventListener,
          state.removeEventListener,
        ],
        []
      ),
      shallow
    );

  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);

  useEffect(() => {
    const timer: NodeJS.Timeout[] = [];
    const loadingTime = 10; // seconds

    const searchStart = () => {
      setLoadingProgress(0);
      setIsLoading(true);
      window.scrollTo({ top: 0 });

      for (let i = 1; i <= loadingTime; i++) {
        timer.push(
          setTimeout(
            () => setLoadingProgress((i / loadingTime) * 100),
            i * 1000
          )
        );
      }
    };
    const searchSuccess = () => {
      setIsLoading(false);
      timer.forEach((timeout) => clearTimeout(timeout));
    };

    addEventListener("searchstart", searchStart);
    addEventListener("searchsuccess", searchSuccess);

    return () => {
      timer.forEach((timeout) => clearTimeout(timeout));
      setIsLoading(false);
      setLoadingProgress(0);
      removeEventsListener("searchstart", searchStart);
      removeEventsListener("searchsuccess", searchSuccess);
    };
  }, [addEventListener, removeEventsListener]);

  const handleClick: MouseEventHandler = useCallback(async () => {
    await fetchReport();
  }, [fetchReport]);

  return (
    <Button
      id="button-search-result"
      disabled={!isValid || isLoading}
      className={`bg-[#43357F] disabled:bg-[#C2B6CB] ${
        !isLoading ? "disabled:cursor-not-allowed" : "disabled:cursor-progress"
      }`}
      onClick={handleClick}
    >
      {!isLoading ? "Search" : `Loading... ${Math.floor(loadingProgress)}%`}
    </Button>
  );
};

export default SearchButton;
