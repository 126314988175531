import { FC, useEffect, useRef } from "react";
import "../../animation.css";
import * as d3 from "d3";

const linesCount = 9;
const linesData: number[] = [];
for (let i = 0; i < linesCount; i++) {
  linesData.push(i);
}

const barsCount = 20;
const barsData: number[] = [];
for (let i = 0; i < barsCount; i++) {
  barsData.push(i);
}

const ResultLoadingText: FC<{
  className?: string;
  animated?: "short" | "long" | "short-black" | "long-black";
}> = ({ className, animated = "short" }) => (
  <div
    className={`initial:h-3 initial:w-[75px] initial:rounded-full initial:last:w-[120px] animated-${animated} ${className}`}
  ></div>
);

const ResultLoading = () => {
  const graphContainerRef = useRef<HTMLElement>(null);
  const animatedGraphRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const graphContainer = graphContainerRef.current;
    if (!graphContainer) return;

    const width = graphContainer.clientWidth;
    const height = 126;

    const svg = d3
      .select(animatedGraphRef.current)
      .attr("viewBox", [0, 0, width, height]);

    const graph = svg.append("g");

    for (let i = 0; i < Math.ceil(linesCount / 2); i++) {
      if (i < 1) {
        graph
          .append("line")
          .attr("stroke", "#AEAEAE")
          .attr("stroke-dasharray", "2")
          .attr("opacity", 0.3)
          .attr("x1", 0)
          .attr("x2", width)
          .attr("y1", height / 2)
          .attr("y2", height / 2);
      } else {
        graph
          .append("line")
          .attr("stroke", "#AEAEAE")
          .attr("stroke-dasharray", "2")
          .attr("opacity", 0.3)
          .attr("x1", 0)
          .attr("x2", width)
          .attr("y1", height / 2 + i * (height / linesCount))
          .attr("y2", height / 2 + i * (height / linesCount));

        graph
          .append("line")
          .attr("stroke", "#AEAEAE")
          .attr("stroke-dasharray", "2")
          .attr("opacity", 0.3)
          .attr("x1", 0)
          .attr("x2", width)
          .attr("y1", height / 2 - i * (height / linesCount))
          .attr("y2", height / 2 - i * (height / linesCount));
      }
    }

    const maxBarHeight = (height / linesCount) * 4;
    const minBarHeight = 11;
    const initialBarHeight = (height / linesCount) * 3;
    let reachedMaxHeight = false;
    let reachedMinHeight = false;
    let barHeight = initialBarHeight;

    const bars = graph.selectAll("path").data(barsData).enter();
    bars
      .append("path")
      .attr("fill", (d) => (d < 10 ? "#c9e7e7" : "#ffd7d7"))
      .attr("class", "animated-bar")
      .attr("style", (d) => {
        return `--order: ${d}`;
      })
      .attr("d", (d) => {
        const isApprove = d < 10;
        let x = d * ((width / barsCount) * 1.04);
        let y = height / 2;
        let w = 10;
        let h = barHeight;

        let path: string[] = [];

        if (isApprove) {
          path = [
            `M ${x},${y}`,
            `v ${-h + w / 2}`,
            `q ${0},${-(w / 2)} ${w / 2},${-(w / 2)}`,
            `h 0`,
            `q ${w / 2},0 ${w / 2},${w / 2}`,
            `v ${h - w / 2}`,
            `z`,
          ];
        } else {
          path = [
            `M ${x},${y}`,
            `v ${h - w / 2}`,
            `q ${0},${w / 2} ${w / 2},${w / 2}`,
            `h 0`,
            `q ${w / 2},0 ${w / 2},${-w / 2}`,
            `v ${-h + w / 2}`,
            `z`,
          ];
        }

        if (!reachedMaxHeight) {
          const newHeight = barHeight + maxBarHeight / 8;
          barHeight = newHeight >= maxBarHeight ? maxBarHeight : newHeight;
        }

        if (reachedMaxHeight && !reachedMinHeight) {
          const newHeight = barHeight - maxBarHeight / 8;
          barHeight = newHeight <= minBarHeight ? minBarHeight : newHeight;
        }

        if (barHeight >= maxBarHeight) {
          reachedMinHeight = false;
          reachedMaxHeight = true;
        }

        if (barHeight <= minBarHeight) {
          reachedMaxHeight = false;
          reachedMinHeight = true;
        }

        return path.join(" ");
      });

    return () => {
      graph.remove();
    };
  }, []);

  return (
    <section className="p-4">
      <header className="flex items-center justify-between">
        <ResultLoadingText animated="short-black" />
        <ResultLoadingText animated="short-black" />
      </header>
      <main className="mt-8 rounded-[3px] bg-[#E4EDF7] p-3">
        <section className="rounded-[3px] bg-white p-4 shadow-[0_0_8px_0_#cdd7e2]">
          <header className="flex items-center justify-between">
            <ResultLoadingText />
            <ResultLoadingText />
          </header>
          <main className="mt-8" ref={graphContainerRef}>
            <svg id="animated-graph" ref={animatedGraphRef} />
          </main>
        </section>
        <section className="mt-4 rounded-[3px] bg-white p-4 shadow-[0_0_8px_0_#cdd7e2]">
          <header className="flex items-center justify-between">
            <ResultLoadingText />
            <div className="flex items-center justify-between space-x-2">
              <ResultLoadingText />
              <ResultLoadingText />
            </div>
          </header>
          <main className="mt-8">
            <ResultLoadingText className="w-full last:w-full" animated="long" />
          </main>
        </section>
      </main>
      <section className="mt-8">
        <ResultLoadingText />
        <main className="mt-8 divide-y divide-[#CDC3D2] rounded-[3px] border border-[#CDC3D2]">
          {[0, 1, 2].map((item) => (
            <div key={item} className="flex items-center justify-between p-4">
              <ResultLoadingText animated="short-black" />
              <div className="flex items-center space-x-2">
                <ResultLoadingText animated="short-black" />
                <ResultLoadingText animated="short-black" />
                <ResultLoadingText
                  animated="short-black"
                  className="last:w-[75px]"
                />
              </div>
            </div>
          ))}
        </main>
      </section>
    </section>
  );
};

export default ResultLoading;
