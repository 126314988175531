import { ReactNode, createContext, useContext } from "react";
import { twMerge } from "tailwind-merge";

type Props<T> = {
  id?: string;
  onChange?: (value: T) => void;
  className?: string;
  value?: T;
  children?: ReactNode;
};

const InputSwithContext = createContext<{
  value: any;
  updateValue: (newValue: any) => void;
}>({ value: undefined, updateValue: () => {} });

export default function InputSwitch<T extends any>({
  id,
  onChange,
  className,
  value,
  children,
}: Props<T>) {
  function updateValue(newValue: T) {
    onChange && onChange(newValue);
  }

  return (
    <InputSwithContext.Provider
      value={{
        value,
        updateValue,
      }}
    >
      <div
        className={twMerge(
          "flex items-center justify-between text-center font-helvetica text-xs text-[#7B5C8D] desktop:text-sm",
          (typeof value === "undefined" || value === null) &&
            "relative after:absolute after:bottom-0 after:left-1/2 after:top-0 after:w-px after:bg-[#C2B6CB]",
          className
        )}
      >
        {children}
      </div>
    </InputSwithContext.Provider>
  );
}

InputSwitch.InputSwitchOption = function InputSwitchOption<T extends any>({
  value,
  children,
}: {
  value: T;
  children?: ReactNode;
}) {
  const context = useContext(InputSwithContext);
  return (
    <span
      className={`block w-full rounded-[3px] border border-[#C2B6CB] py-1 font-bold first:rounded-r-none first:border-r-0 last:rounded-l-none last:border-l-0 hover:cursor-pointer ${
        context.value === value
          ? "!border-[#7B5C8D] bg-[#7B5C8D] text-white"
          : "bg-[#FDFBFF]"
      }`}
      onClick={() => context.updateValue(value)}
    >
      {children}
    </span>
  );
};
