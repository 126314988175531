import { FC, useMemo, useState } from "react";
import {
  ResultFinancial,
  ResultFinancialTable,
  ResultFinancialTableData,
  ResultFinancialTableRow,
  ResultLendersDetails,
} from ".";
import { Button } from "../components/form";
import { DollarCircle, Search } from "../icons";
import { SectionLabel } from "../components/label";
import { IAssessedExpense, IAssessedIncome, ILenderResult } from "./report";
import { decimalToPercentage, generateId } from "../util";
import currency from "currency.js";
import { isNumber } from "lodash";
import LenderSpreadsheetModal from "./LenderSpreadsheetModal";

type IGroupedExpense = {
  readonly _id: number;
  Group: string;
  AssessedExpense: Omit<IAssessedExpense & { readonly _id: number }, "Group">[];
};

type IGroupedIncome = {
  readonly _id: number;
  Group: string;
  AssessedIncome: Omit<IAssessedIncome & { readonly _id: number }, "Group">[];
};

const LenderStatsServicing: FC<{
  lenderResult: ILenderResult;
  spreadsheetImage?: string;
}> = ({ lenderResult, spreadsheetImage }) => {
  const { Result, Errors, Warnings, Lender } = lenderResult;
  const { UMI, Servicing } = Result || {};
  const { AssessedIncome, AssessedExpense } = Result || {};

  const groupedExpense = useMemo(() => {
    if (!AssessedExpense) return [];

    return AssessedExpense.reduce<IGroupedExpense[]>((acc, current) => {
      const group = acc.find((group) => group.Group === current.Group);

      if (!group) {
        acc.push({
          _id: generateId(),
          Group: current.Group,
          AssessedExpense: [
            {
              _id: generateId(),
              Assessed: current.Assessed,
              Entered: current.Entered,
              Label: current.Label,
            },
          ],
        });

        return acc;
      }

      group.AssessedExpense.push({
        _id: generateId(),
        Assessed: current.Assessed,
        Entered: current.Entered,
        Label: current.Label,
      });

      return acc;
    }, []);
  }, [AssessedExpense]);

  const groupedIncome = useMemo(() => {
    if (!AssessedIncome) return [];

    return AssessedIncome.reduce<IGroupedIncome[]>((acc, current) => {
      const group = acc.find((group) => group.Group === current.Group);

      if (!group) {
        acc.push({
          _id: generateId(),
          Group: current.Group,
          AssessedIncome: [
            {
              _id: generateId(),
              Assessed: current.Assessed,
              Entered: current.Entered,
              Label: current.Label,
            },
          ],
        });

        return acc;
      }

      group.AssessedIncome.push({
        _id: generateId(),
        Assessed: current.Assessed,
        Entered: current.Entered,
        Label: current.Label,
      });

      return acc;
    }, []);
  }, [AssessedIncome]);

  const [spreadsheetModal, setSpreadsheetModal] = useState<string>();

  const isPass = useMemo(() => {
    if (Servicing?.Type === "N/A") {
      return UMI && UMI.Status !== "N/A" ? UMI.Status === "Pass" : undefined;
    }

    return Servicing && Servicing.Status !== "N/A"
      ? Servicing.Status === "Pass"
      : undefined;
  }, [Servicing, UMI]);

  const title = useMemo(() => {
    if (!Servicing || Servicing?.Type === "N/A") {
      if (!UMI || UMI.Status === "N/A") return "Servicing: N/A";

      return `${currency(UMI.Value).format()} UMI`;
    }

    return `${currency(Servicing.Value).format()} ${Servicing.Type}`;
  }, [Servicing, UMI]);

  const incomeError = useMemo(
    () => Errors.filter((err) => err.Reference === "Result.Income"),
    [Errors]
  );

  const incomeWarning = useMemo(
    () => Warnings.filter((warning) => warning.Reference === "Result.Income"),
    [Warnings]
  );

  const servicingErrors = useMemo(
    () => Errors.filter((err) => err.Reference === "Result.Servicing"),
    [Errors]
  );
  const servicingWarnings = useMemo(
    () => Warnings.filter((warn) => warn.Reference === "Result.Servicing"),
    [Warnings]
  );

  const expensesErrors = useMemo(
    () => Errors.filter((err) => err.Reference === "Result.Expenses"),
    [Errors]
  );
  const expensesWarnings = useMemo(
    () => Warnings.filter((warn) => warn.Reference === "Result.Expenses"),
    [Warnings]
  );
  return (
    <div
      className={`mx-4 space-y-12 rounded-[3px] bg-white px-[14px] py-[11px] ${
        typeof isPass !== "undefined"
          ? isPass === true
            ? "border border-accent"
            : "border border-[#FF6E6E]"
          : ""
      }`}
    >
      <LenderSpreadsheetModal
        open={!!spreadsheetModal}
        sectionID={spreadsheetModal}
        lender={Lender.Key}
        handleClose={() => setSpreadsheetModal(undefined)}
        spreadsheetImage={spreadsheetImage}
      />
      <ResultLendersDetails
        title={title}
        icon={
          <DollarCircle
            fill={
              typeof isPass !== "undefined"
                ? isPass
                  ? "#42B4B4"
                  : "#FF6E6E"
                : undefined
            }
          />
        }
        pass={isPass}
      >
        {groupedIncome.length > 0 && (
          <ResultFinancial
            title="INCOME"
            badge={`Total: ${currency(
              Result?.TotalMonthlyIncome || 0
            ).format()} p/m`}
            onSearch={() => setSpreadsheetModal("income-section")}
            warning={incomeWarning}
            error={incomeError}
          >
            <div className="space-y-4">
              {groupedIncome.map((group) => (
                <section key={group._id}>
                  <SectionLabel className="mb-1 text-[11px] desktop:text-xs">
                    {group.Group.toUpperCase()}
                  </SectionLabel>
                  <ResultFinancialTable>
                    {group.AssessedIncome.map(
                      ({ _id, Assessed, Entered, Label }) => (
                        <ResultFinancialTableRow key={_id}>
                          <ResultFinancialTableData>
                            {Label}
                          </ResultFinancialTableData>
                          <ResultFinancialTableData>
                            {Entered && Entered.Amount !== null
                              ? currency(Entered.Amount).format()
                              : "N/A"}{" "}
                            {Entered && Entered.Frequency
                              ? `p/${Entered.Frequency.charAt(
                                  0
                                ).toLocaleLowerCase()}`
                              : ""}
                          </ResultFinancialTableData>
                          <ResultFinancialTableData>
                            {Assessed && Assessed.Amount !== null
                              ? currency(Assessed.Amount).format()
                              : "N/A"}{" "}
                            {Assessed && Assessed.Frequency
                              ? `p/${Assessed.Frequency?.charAt(
                                  0
                                ).toLocaleLowerCase()}`
                              : ""}
                          </ResultFinancialTableData>
                        </ResultFinancialTableRow>
                      )
                    )}
                  </ResultFinancialTable>
                </section>
              ))}
            </div>
          </ResultFinancial>
        )}
        {groupedExpense.length > 0 && (
          <ResultFinancial
            title="EXPENSES"
            badge={`Total Expenses: ${currency(
              Result?.TotalMonthlyExpense || 0
            ).format()} p/m`}
            containerClassName="mt-[20px]"
            onSearch={() => setSpreadsheetModal("expense-section")}
            error={expensesErrors}
            warning={expensesWarnings}
          >
            <div className="space-y-4">
              {groupedExpense.map((group) => (
                <section key={group._id}>
                  <SectionLabel className="mb-1 text-[11px] desktop:text-xs">
                    {group.Group.toUpperCase()}
                  </SectionLabel>
                  <ResultFinancialTable>
                    {group.AssessedExpense.map(
                      ({ _id, Label, Entered, Assessed }) => (
                        <ResultFinancialTableRow key={_id}>
                          <ResultFinancialTableData>
                            {Label}
                          </ResultFinancialTableData>
                          <ResultFinancialTableData>
                            {currency(Entered.Amount).format()}{" "}
                            {Entered.Definition || null}
                            {Entered.Frequency && Entered.Frequency !== "Limit"
                              ? `p/${Entered.Frequency?.charAt(
                                  0
                                ).toLocaleLowerCase()}`
                              : ""}
                            {Entered.InterestRate > 0 &&
                              !Entered.Definition &&
                              !Entered.Frequency &&
                              `@ ${decimalToPercentage(Entered.InterestRate)}`}
                          </ResultFinancialTableData>
                          <ResultFinancialTableData>
                            {currency(Assessed.Amount).format()}{" "}
                            {Assessed.Frequency &&
                            Assessed.Frequency !== "Limit"
                              ? `p/${Assessed.Frequency?.charAt(
                                  0
                                ).toLocaleLowerCase()}`
                              : ""}
                          </ResultFinancialTableData>
                        </ResultFinancialTableRow>
                      )
                    )}
                  </ResultFinancialTable>
                </section>
              ))}
            </div>
          </ResultFinancial>
        )}
        <ResultFinancial
          title="SERVICING CALCULATOR"
          // badge={`Required UMI: ${
          //   isNumber(UMI?.Required)
          //     ? `${currency(UMI?.Required || 0).format()}+ p/m`
          //     : "N/A"
          // }`}
          badge={
            !Servicing || Servicing.Type === "N/A"
              ? `Minimum UMI: ${
                  isNumber(UMI?.Required)
                    ? `${currency(UMI?.Required || 0).format()}+ p/m`
                    : "N/A"
                }`
              : `Maximum ${Servicing.Type}: ${decimalToPercentage(
                  Servicing.Required
                )}`
          }
          containerClassName="mt-[20px]"
          error={servicingErrors}
          warning={servicingWarnings}
        >
          <ResultFinancialTable withoutHead>
            <ResultFinancialTableRow>
              <ResultFinancialTableData>Total Income</ResultFinancialTableData>
              <ResultFinancialTableData />
              <ResultFinancialTableData>
                {currency(Result?.TotalMonthlyIncome || 0).format()} p/m
              </ResultFinancialTableData>
            </ResultFinancialTableRow>
            <ResultFinancialTableRow>
              <ResultFinancialTableData>Total Expense</ResultFinancialTableData>
              <ResultFinancialTableData />
              <ResultFinancialTableData>
                {currency(Result?.TotalMonthlyExpense || 0).format()} p/m
              </ResultFinancialTableData>
            </ResultFinancialTableRow>
            <ResultFinancialTableRow>
              <ResultFinancialTableData className="col-span-2">
                <span className="font-bold">
                  Bank Calculated{" "}
                  {Servicing?.Type === "N/A" ? "UMI" : Servicing?.Type}
                </span>
              </ResultFinancialTableData>
              <ResultFinancialTableData>
                <span className="block w-full font-bold">
                  {!Servicing || Servicing?.Type === "N/A"
                    ? `${currency(UMI?.Value || 0).format()} p/m`
                    : `${decimalToPercentage(Servicing?.Value)}`}
                </span>
              </ResultFinancialTableData>
              <ResultFinancialTableData>
                <Button
                  size="small"
                  withIcon={<Search />}
                  className="ml-auto h-full w-min bg-transparent"
                  onClick={() => setSpreadsheetModal("servicing-section")}
                />
              </ResultFinancialTableData>
            </ResultFinancialTableRow>
          </ResultFinancialTable>
        </ResultFinancial>
      </ResultLendersDetails>
    </div>
  );
};

export default LenderStatsServicing;
