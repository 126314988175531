import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import * as firebaseui from "firebaseui";
import { firebaseConfig } from "../constant";
import useStore from "./store";

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const ui = new firebaseui.auth.AuthUI(auth);
export const db = getFirestore(app);

auth.onAuthStateChanged((user) => {
  if (!user) {
    useStore.setState({ currentAdmin: null });
    return;
  }

  useStore.setState({
    currentAdmin: {
      email: user.email,
      uid: user.uid,
    },
  });
});
