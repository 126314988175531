import {
  FC,
  memo,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Check,
  LenderBlank,
  NotApplicable,
  Triangle,
  WarningCircleSm,
} from "../icons";
import { SectionLabel } from "../components/label";
import { ILenderResult } from "./report";
import { LenderStats } from ".";
import { Collapse } from "../components/collapse";
import { Alert } from "../components/alert";
import { Button } from "../components/form";
import axios from "axios";
import useStore from "../store";
import download from "../util/download";
import shallow from "zustand/shallow";
import { SPREADSHEET_IMAGE_FUNCTION_URL } from "../../constant";
import { decimalToPercentage } from "../util";
import { twMerge } from "tailwind-merge";

const Lender: FC<{
  lenderResult: ILenderResult;
  index: number;
}> = ({ lenderResult }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [spreadsheetImage, setSpreadsheetImage] = useState<string>();

  const [LoanAmount, spreadsheetFile] = useStore(
    useCallback(
      (state) => {
        const lenderKey = lenderResult.Lender.Key;
        const LoanAmount = state.activeLenders?.LoanAmount;
        const spreadsheetFile = state.getSpreadsheetFile(
          `${lenderKey}_${LoanAmount}`
        );

        return [LoanAmount, spreadsheetFile];
      },
      [lenderResult.Lender.Key]
    ),
    shallow
  );

  const lenderReport = useRef<HTMLTableSectionElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const toggleLender = useCallback(() => {
    setIsOpen((open) => !open);

    if (!isOpen) {
      setTimeout(() => {
        lenderReport.current?.scrollIntoView({
          behavior: "smooth",
        });
      }, 200);
    }
  }, [isOpen]);

  const { Lender, Result, Errors } = lenderResult;
  const { UMI, LVR, DTI, Servicing } = Result || {};

  const errors = useMemo(
    () => Errors.filter((err) => err.Reference === "Top"),
    [Errors]
  );
  const hasErrors = useMemo(() => errors.length > 0, [errors.length]);

  const [isErrorsVisible, setIsErrorsVisible] = useState(false);
  const toggleErrors: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      setIsErrorsVisible((prev) => !prev);
    },
    []
  );
  const hideErrors = useCallback(() => setIsErrorsVisible(false), []);

  const handleDownload = useCallback(() => {
    if (!spreadsheetFile?.DownloadURL) return;
    setIsDownloading(true);
    download(spreadsheetFile?.DownloadURL);
    const timeout = setTimeout(() => {
      setIsDownloading(false);
      clearTimeout(timeout);
    }, 500);
  }, [spreadsheetFile?.DownloadURL]);

  useEffect(() => {
    if (!isOpen) return;

    if (spreadsheetImage) {
      if (spreadsheetFile?.LoanAmount !== LoanAmount) {
        setSpreadsheetImage(undefined);
      }

      return;
    }

    if (!spreadsheetFile?.FileName) return;

    axios
      .request({
        method: "get",
        url: SPREADSHEET_IMAGE_FUNCTION_URL,
        params: {
          FileName: spreadsheetFile.FileName,
        },
        responseType: "blob",
      })
      .then(({ data }) => {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(data);
        setSpreadsheetImage(imageUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [LoanAmount, isOpen, spreadsheetFile, spreadsheetImage]);

  const servicingStatus =
    !Servicing || Servicing.Type === "N/A" ? UMI?.Status : Servicing.Status;

  return (
    <tbody
      ref={lenderReport}
      className={`transition-colors duration-200 ease-in-out`}
    >
      <tr className={twMerge(hasErrors && "bg-[#FBF1F1]")}>
        <th
          className={twMerge(
            "w-[35%] p-0",
            !isOpen && "bg-[#FCFBFD]",
            isOpen && "sticky top-0 h-0 bg-[#F5F1F8]"
          )}
        >
          <div className="flex h-[47px] items-center space-x-[20px] border-b border-[#C2B6CB] px-4">
            <button
              className="w-max rounded-[3px] border border-[#C2B6CB] bg-gradient-to-b from-white to-[#F7EFFA] px-1 py-1.5"
              onClick={toggleLender}
              style={{
                visibility: !hasErrors ? "visible" : "hidden",
              }}
            >
              <Triangle
                className={`all transition duration-300 ease-in ${
                  isOpen ? "" : "rotate-180"
                }`}
              />
            </button>
            <figure className="flex items-center space-x-1.5">
              {Lender.Logo && (
                <img
                  src={Lender.Logo}
                  alt={Lender.Name}
                  width={38}
                  height={21}
                  className="h-[21px] w-[38px] object-contain object-center"
                />
              )}
              {!Lender.Logo && <LenderBlank className="mr-1" />}
              <figcaption>
                <SectionLabel className="text-[11px] desktop:text-xs">
                  {Lender.Name}
                </SectionLabel>
              </figcaption>
            </figure>
          </div>
        </th>
        {!hasErrors && (
          <>
            <th
              className={twMerge(
                "w-[15%] p-0",
                !isOpen && "bg-[#FCFBFD]",
                isOpen && "sticky top-0 h-0 bg-[#F5F1F8]"
              )}
            >
              <div className="grid h-[47px] place-items-center border-b border-[#C2B6CB]">
                {servicingStatus === "Fail" && (
                  <WarningCircleSm width={16} height={16} fill="#FF6E6E" />
                )}
                {servicingStatus === "Pass" && <Check />}
                {servicingStatus === "N/A" && <NotApplicable />}
              </div>
            </th>
            <th
              className={twMerge(
                "w-[15%] p-0",
                !isOpen && "bg-[#FCFBFD]",
                isOpen && "sticky top-0 h-0 bg-[#F5F1F8]"
              )}
            >
              <div className="grid h-[47px] place-items-center border-b border-[#C2B6CB]">
                {LVR && (
                  <div className="flex items-center gap-0.5">
                    {LVR.map((lvrItem, index) => (
                      <span
                        key={index}
                        className={twMerge(
                          "rounded-[11px] px-2 py-[3px] font-helvetica text-xs text-white",
                          lvrItem.Status === "Pass" && "bg-[#4EC0C1]",
                          lvrItem.Status === "Fail" && "bg-[#FF8581]",
                          lvrItem.Status === "N/A" && "bg-[#907AA0]"
                        )}
                      >
                        {typeof lvrItem.Value === "number"
                          ? decimalToPercentage(lvrItem.Value)
                          : lvrItem.Value}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </th>
            <th
              className={twMerge(
                "w-[15%] p-0",
                !isOpen && "bg-[#FCFBFD]",
                isOpen && "sticky top-0 h-0 bg-[#F5F1F8]"
              )}
            >
              <div className="grid h-[47px] place-items-center border-b border-[#C2B6CB]">
                {DTI?.Status === "Fail" && (
                  <WarningCircleSm width={16} height={16} fill="#FF6E6E" />
                )}
                {DTI?.Status === "Pass" && <Check />}
                {DTI?.Status === "N/A" && <NotApplicable />}
              </div>
            </th>
            <th
              className={twMerge(
                "w-[20%] p-0",
                !isOpen && "bg-[#FCFBFD]",
                isOpen && "sticky top-0 h-0 bg-[#F5F1F8]"
              )}
            >
              <div className="grid h-[47px] place-items-center border-b border-[#C2B6CB]">
                <button
                  type="button"
                  onClick={handleDownload}
                  className={`group flex items-center justify-center space-x-[5px] rounded-[3px] border border-[#CDC3D2] px-3 py-[3px] font-helvetica text-[11px] text-[#6F5F7B] transition-colors duration-200 ease-in-out hover:bg-[#43357F] hover:text-white disabled:cursor-not-allowed disabled:opacity-60 disabled:hover:bg-transparent disabled:hover:text-[#6F5F7B] desktop:text-xs`}
                  disabled={isDownloading || !spreadsheetFile?.DownloadURL}
                >
                  {!isDownloading && spreadsheetFile?.DownloadURL && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="13px"
                      height="14px"
                      viewBox="0 0 13 14"
                      version="1.1"
                    >
                      <title>icon-xls</title>
                      <g
                        id="Errors"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Overview"
                          transform="translate(-1065.000000, -653.000000)"
                          fillRule="nonzero"
                          className={`fill-[#6F5F7B] transition-colors duration-200 ease-in-out ${
                            !isDownloading ? "group-hover:fill-[#ffffff]" : ""
                          }`}
                        >
                          <g
                            id="icon-xls"
                            transform="translate(1065.000000, 653.000000)"
                          >
                            <path
                              d="M9.27767584,5.24464832 L7.42956167,5.24464832 L6.12232416,7.17125382 L4.81508665,5.24464832 L2.96697248,5.24464832 L5.18185525,8.55555556 L2.68297655,12.2375127 L6.26503568,12.2375127 L6.26503568,10.9873598 L5.43730887,10.9873598 L6.12232416,9.96269113 L7.64362895,12.2375127 L9.56167176,12.2375127 L7.06279307,8.55555556 L9.27767584,5.24464832 Z M11.4682977,2.7243629 L9.51743119,0.772069317 C9.09215087,0.348216106 8.25300714,0 7.65219164,0 L1.09602446,0 C0.49520897,0 0.00285423038,0.49235474 0.00285423038,1.09317023 L0.00285423038,12.8939857 C0.00285423038,13.4948012 0.49520897,13.987156 1.09602446,13.987156 L11.1486239,13.987156 C11.7494393,13.987156 12.2417941,13.4948012 12.2417941,12.8939857 L12.2417941,4.58960245 C12.2417941,3.98878695 11.893578,3.14821611 11.4682977,2.7243629 Z M10.8503568,3.34230377 C10.8931702,3.38511723 10.9359837,3.43792049 10.97737,3.49643221 L8.74536188,3.49643221 L8.74536188,1.26442406 C8.80530071,1.3058104 8.85667686,1.34862385 8.89949032,1.39143731 L10.8503568,3.34230377 Z M11.3669725,12.8925586 C11.3669725,13.0110092 11.2670744,13.1109072 11.1486239,13.1109072 L1.09602446,13.1109072 C0.977573904,13.1109072 0.877675841,13.0110092 0.877675841,12.8925586 L0.877675841,1.09317023 C0.877675841,0.974719674 0.977573904,0.874821611 1.09602446,0.874821611 L7.65219164,0.874821611 C7.71783894,0.874821611 7.79204893,0.883384302 7.87054027,0.897655454 L7.87054027,4.37125382 L11.3441386,4.37125382 C11.3584098,4.44974516 11.3669725,4.52395515 11.3669725,4.58960245 L11.3669725,12.8925586 Z"
                              id="Shape"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  )}
                  {(isDownloading || !spreadsheetFile?.DownloadURL) && (
                    <svg
                      className="h-4 w-4 animate-spin text-gray-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  <span>
                    {!isDownloading
                      ? spreadsheetFile?.DownloadURL
                        ? "Download .xls"
                        : "Fetching download URL"
                      : "Downloading"}
                  </span>
                </button>
              </div>
            </th>
          </>
        )}
        {hasErrors && (
          <th className="p-0" colSpan={4}>
            <div className="flex h-[47px] items-center justify-end border-b border-[#C2B6CB] pr-4">
              <WarningCircleSm width={16} height={16} fill="#FF6E6E" />
              <span className="ml-[10px] flex items-center font-helvetica font-normal text-[#F18383]">
                No calculations performed for this lender.
                <Alert
                  open={isErrorsVisible}
                  onClose={hideErrors}
                  below={
                    <>
                      <Button
                        className="w-max bg-transparent p-0 px-2 text-accent underline"
                        onClick={toggleErrors}
                      >
                        Learn more.
                      </Button>
                    </>
                  }
                  backgroundColor="#1E1642"
                >
                  <div className="space-y-3">
                    {errors.map((err) => (
                      <p
                        key={err.Message}
                        className="flex items-start space-x-[10px] text-left text-[11px] text-white desktop:text-xs"
                      >
                        <WarningCircleSm
                          width={16}
                          height={16}
                          fill="#FF6E6E"
                          className="flex-shrink-0"
                        />
                        <span>{err.Message}</span>
                      </p>
                    ))}
                  </div>
                </Alert>
              </span>
            </div>
          </th>
        )}
      </tr>
      <tr
        className={twMerge(
          "bg-[#F5F1F8]",
          isOpen && "last:border-b last:border-[#C2B6CB]"
        )}
      >
        <td colSpan={5} className="p-0">
          <Collapse collapse={!isOpen}>
            <LenderStats
              lenderResult={lenderResult}
              spreadsheetImage={spreadsheetImage}
            />
          </Collapse>
        </td>
      </tr>
    </tbody>
  );
};

export default memo(Lender);
