import { FirebaseOptions } from "firebase/app";

export const USERSNAP_GLOBAL_API_KEY = "0c27c33e-7dcf-429a-9613-249a7ba43014";
export const FUNCTIONS_BASE_URL =
  "https://us-central1-mortgage-rec-engine-dev-msec.cloudfunctions.net";
export const SPREADSHEET_IMAGE_FUNCTION_URL =
  "https://spreadsheet-to-image-service-wwd5ltsw2q-uc.a.run.app/getPreviewImage";
export const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyAcOfZhdZVkZnfZEQQ0LjVs5cxokY-2XVY",
  authDomain: "mortgage-rec-engine-dev-msec.firebaseapp.com",
  projectId: "mortgage-rec-engine-dev-msec",
  storageBucket: "mortgage-rec-engine-dev-msec.appspot.com",
  messagingSenderId: "348556486555",
  appId: "1:348556486555:web:e335605ac00034f5bce33e",
};
export const APP_URL = "https://mortgage-rec-engine-dev-msec.web.app";
