import currency from "currency.js";
import { FC, useMemo, useState } from "react";
import {
  ResultFinancial,
  ResultFinancialTable,
  ResultFinancialTableData,
  ResultFinancialTableRow,
  ResultLendersDetails,
} from ".";
import { DTIIcon, Search } from "../icons";
import { ILenderResult, IResource } from "./report";
import { Modal } from "../components/modal";
import { Button } from "../components/form";

const LenderStatsDTI: FC<{
  lenderResult: ILenderResult;
}> = ({ lenderResult }) => {
  const { Result, Errors, Warnings, Lender, Resources } = lenderResult;
  const { Value, Status, TotalDebt, TotalIncome, RequiredMaxDTI } =
    Result?.DTI || {};
  const isPass = Status !== "N/A" ? Status === "Pass" : undefined;

  const errors = useMemo(
    () => Errors.filter((err) => err.Reference === "Result.DTI"),
    [Errors]
  );

  const warnings = useMemo(
    () => Warnings.filter((err) => err.Reference === "Result.DTI"),
    [Warnings]
  );
  return (
    <div
      className={`mx-4 rounded-[3px] bg-white px-[14px] py-[11px] ${
        typeof isPass !== "undefined"
          ? isPass === true
            ? "border border-accent"
            : "border border-[#FF6E6E]"
          : ""
      }`}
    >
      <ResultLendersDetails
        title={`DTI: ${
          Status !== "N/A"
            ? Value?.toFixed(2)
            : Lender.Key === "bnz"
            ? Value && Value.toFixed(2)
            : "N/A"
        }`}
        pass={isPass}
        icon={
          <DTIIcon
            fill={
              typeof isPass !== "undefined"
                ? isPass
                  ? "#42B4B4"
                  : "#FF6E6E"
                : undefined
            }
          />
        }
      >
        {(Status !== "N/A" || (Status === "N/A" && Lender.Key === "bnz")) && (
          <ResultFinancial
            title=""
            className="pt-1 pb-3"
            badge={
              <RequiredMaxDTIBadge
                RequiredMaxDTI={RequiredMaxDTI}
                snippet={Resources.find(
                  (resource) => resource.Reference === "Result.DTI"
                )}
              />
            }
            withoutHeader
            error={errors}
            warning={warnings}
          >
            <ResultFinancialTable withoutHead>
              <ResultFinancialTableRow>
                <ResultFinancialTableData>
                  <span>Total Debt</span>
                </ResultFinancialTableData>
                <ResultFinancialTableData />
                <ResultFinancialTableData>
                  <span>
                    {currency(TotalDebt || 0, { precision: 2 }).format()}
                  </span>
                </ResultFinancialTableData>
              </ResultFinancialTableRow>
              <ResultFinancialTableRow>
                <ResultFinancialTableData>
                  <span>Total Income</span>
                </ResultFinancialTableData>
                <ResultFinancialTableData />
                <ResultFinancialTableData>
                  <span>
                    {currency(TotalIncome || 0, { precision: 2 }).format()}
                  </span>
                </ResultFinancialTableData>
              </ResultFinancialTableRow>
              <ResultFinancialTableRow>
                <ResultFinancialTableData>
                  <span>Debt to Income Ratio</span>
                </ResultFinancialTableData>
                <ResultFinancialTableData />
                <ResultFinancialTableData>
                  <span>{Value}</span>
                </ResultFinancialTableData>
              </ResultFinancialTableRow>
            </ResultFinancialTable>
          </ResultFinancial>
        )}
      </ResultLendersDetails>
    </div>
  );
};

function RequiredMaxDTIBadge({
  RequiredMaxDTI,
  snippet,
}: {
  RequiredMaxDTI?: number;
  snippet?: IResource;
}) {
  const [showSnippet, setShowSnippet] = useState(false);

  if (!snippet) return <>Max DTI: {RequiredMaxDTI}</>;

  return (
    <>
      <Modal
        open={showSnippet}
        withCloseButton
        onClose={() => setShowSnippet(false)}
        closeWithBackdrop
        containerClassName="max-w-[70%] w-max"
      >
        <img
          src={snippet.URL}
          alt=""
          className="mt-[27px] w-full rounded-[3px] border border-[#CDC3D2]"
        />
      </Modal>
      <span className="relative">
        Max DTI: {RequiredMaxDTI}{" "}
        <Button
          size="small"
          withIcon={<Search />}
          onClick={() => setShowSnippet(!showSnippet)}
          className="absolute top-[calc(50%-7px)] right-[-36px] ml-auto h-full w-min bg-transparent"
        />
      </span>
    </>
  );
} 

export default LenderStatsDTI;
