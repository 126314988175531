import {
  ComponentProps,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Expense,
  expenseCategoryOrderData,
  ExpenseForm,
  expenseTypeData,
  IExpense,
} from ".";
import {
  FinancialDetails,
  FinancialDetailsManager,
  FinancialDetailsTable,
} from "../financial";
import { AnimatePresence, motion } from "framer-motion";
import useStore, { AddingFinancialEvent, IModelId } from "../store";
import { Button, InputCheckbox, InputNumber } from "../components/form";
import {
  Spreadsheet,
  InfoRounded,
  Grid,
  Close,
  Triangle,
  Dollar,
  List,
} from "../icons";
import { twMerge } from "tailwind-merge";
import { OptionsGroupsTypes } from "./ExpenseForm";
import { Frequency } from "../applicant";
import { Alert } from "../components/alert";
import { Modal } from "../components/modal";
import { frequencyUtils } from "../util";
import { ExpenseView } from "../financial/FinancialModal";

const ExpensesListView: FC<{
  expenses: IExpense[];
  applicantPartyId: IModelId;
  view: ExpenseView;
}> = ({ expenses, applicantPartyId, view }) => {
  const [addFinancial] = useStore(
    useCallback((state) => [state.addFinancial], [])
  );

  const prevView = useRef(view);
  const [isAdding, setIsAdding] = useState(expenses.length === 0);

  useEffect(() => {
    if (prevView.current === "grid" && expenses.length > 0) {
      setIsAdding(false);
    }

    prevView.current = view;
  }, [view, expenses]);

  const openForm = useCallback(() => setIsAdding(true), []);
  const closeForm = useCallback(() => setIsAdding(false), []);

  const handleSave = useCallback(
    (value) => {
      addFinancial(applicantPartyId, {
        type: "Expense",
        value,
      });
      closeForm();
    },
    [addFinancial, applicantPartyId, closeForm]
  );

  if (view !== "list") return null;

  return (
    <>
      <FinancialDetailsTable isAdding={isAdding}>
        <AnimatePresence>
          {expenses.map((expense, index) => (
            <motion.div
              key={expense._id}
              initial="unmounted"
              animate="open"
              exit="unmounted"
              variants={{
                open: { opacity: 1, height: "auto" },
                unmounted: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <Expense
                applicantPartyId={applicantPartyId}
                formLabel={`Expenses ${index + 1}`}
                id={expense._id}
                key={expense._id}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </FinancialDetailsTable>
      <FinancialDetailsManager
        isAdding={isAdding}
        buttonLabel="Add expense"
        buttonOnClick={openForm}
        hasData={expenses.length > 0}
      >
        <ExpenseForm
          header={`Living Expense ${expenses.length + 1}`}
          onCancel={closeForm}
          onSave={handleSave}
        />
      </FinancialDetailsManager>
    </>
  );
};

const FrequencyConverter: FC<{
  onApply: (
    targetFrequency: Frequency,
    isConvertExistingAmount: boolean
  ) => void;
  currentData: Map<
    IExpense["ExpenseType"],
    Omit<IExpense, "_id" | "Frequency" | "Amount"> & {
      Frequency?: Frequency;
      Amount?: number;
    }
  >;
}> = ({ onApply, currentData }) => {
  const [frequenciesPopupOpen, SetFrequenciesPopup] = useState(false);
  const [targetFrequency, setTargetFrequency] = useState<Frequency>();
  const [isConvertExistingAmount, setIsConvertExistingAmount] =
    useState<boolean>(false);

  const hasEnteredAmount = useMemo(() => {
    return Array.from(currentData.values()).reduce(
      (hasEnteredAmount, currentExpense) =>
        hasEnteredAmount ||
        (typeof currentExpense.Amount !== "undefined" &&
          currentExpense.Amount > 0),
      false
    );
  }, [currentData]);

  return (
    <Alert
      open={frequenciesPopupOpen}
      arrowClassName="hidden"
      className="left-0 right-0 min-w-min translate-y-0 translate-x-0"
      onClose={() => SetFrequenciesPopup(false)}
      below={
        <div
          onClick={(e) => e.stopPropagation()}
          className="ml-auto flex items-center font-helvetica text-[13px] font-bold text-[#7B5C8D]"
        >
          <span>Frequency</span>
          <button
            onClick={() => SetFrequenciesPopup((open) => !open)}
            type="button"
            className={`relative ml-2 flex w-full max-w-[150px] items-center rounded-[3px] bg-[#FDFBFF] px-2 py-1 text-left font-helvetica text-xs text-[#7B5C8D]/50 focus:outline-accent desktop:text-sm`}
          >
            Set all to
            <Triangle className="absolute right-[7px] block rotate-180" />
          </button>
        </div>
      }
    >
      <section onClick={(e) => e.stopPropagation()}>
        <section>
          <header>
            <span className="font-helvetica text-[13px] font-bold text-[#7B5C8D]">
              Set all frequencies to
            </span>
          </header>
          <ul>
            {frequencyUtils.frequencies.map((frequency) => (
              <li
                key={frequency.code}
                className="mt-1 flex items-center first:mt-1.5"
              >
                <InputCheckbox
                  id={frequency.code}
                  className="h-[14px] w-[14px] rounded-full"
                  style={{
                    backgroundSize: "60%",
                  }}
                  checked={targetFrequency === frequency.code}
                  onChange={() => setTargetFrequency(frequency.code)}
                />
                <label
                  htmlFor={frequency.code}
                  className="ml-1.5 inline-block cursor-pointer text-[13px] text-[#7B5C8D]"
                >
                  {frequency.name}
                </label>
              </li>
            ))}
          </ul>
        </section>
        {hasEnteredAmount && (
          <section className="mt-2 border-t border-[#7B5C8D]/20 pt-1.5">
            <header>
              {/* <span className="border-t border-[#7B5C8D]/20 font-helvetica text-[13px] font-bold text-[#7B5C8D]"> */}
              <span className="font-helvetica text-[13px] font-bold text-[#7B5C8D]">
                Convert existing amounts?
              </span>
            </header>
            <ul>
              <li className="mt-1 flex items-center first:mt-1.5">
                <InputCheckbox
                  id="keep-existing-amount"
                  className="h-[14px] w-[14px] rounded-full"
                  style={{
                    backgroundSize: "60%",
                  }}
                  checked={
                    !isConvertExistingAmount &&
                    typeof isConvertExistingAmount !== "undefined"
                  }
                  onChange={() => setIsConvertExistingAmount(false)}
                />
                <label
                  htmlFor={"keep-existing-amount"}
                  className="ml-1.5 inline-block cursor-pointer text-[13px] text-[#7B5C8D]"
                >
                  Keep existing amounts
                </label>
              </li>
              <li className="mt-1 flex items-center first:mt-1.5">
                <InputCheckbox
                  id="convert-existing-amount"
                  className="h-[14px] w-[14px] rounded-full"
                  style={{
                    backgroundSize: "60%",
                  }}
                  checked={isConvertExistingAmount}
                  onChange={() => setIsConvertExistingAmount(true)}
                />
                <label
                  htmlFor={"convert-existing-amount"}
                  className="ml-1.5 inline-block cursor-pointer text-[13px] text-[#7B5C8D]"
                >
                  Convert existing amounts
                </label>
              </li>
            </ul>
          </section>
        )}
        <footer className="mt-4">
          <Button
            disabled={
              typeof targetFrequency === "undefined" ||
              (typeof isConvertExistingAmount === "undefined" &&
                hasEnteredAmount)
            }
            className="py-2 text-[13px] font-bold"
            onClick={() => {
              onApply(targetFrequency!, !!isConvertExistingAmount);
              SetFrequenciesPopup(false);
            }}
          >
            Apply selection
          </Button>
          <Button
            onClick={() => SetFrequenciesPopup(false)}
            className="bg-transparent text-[13px] font-bold  text-[#7B5C8D]"
          >
            Cancel
          </Button>
        </footer>
      </section>
    </Alert>
  );
};

const ExpensesGridView: FC<{
  applicantPartyId: IModelId;
  expenses: IExpense[];
  onChange?: (expenses: IExpense[]) => void;
  view: ExpenseView;
}> = ({ applicantPartyId, expenses, onChange, view }) => {
  const expenseGroups = useMemo(() => {
    const result = expenseTypeData.reduce((previous, current) => {
      const group = previous.find((group) => group.label === current.Group);
      const option = {
        "ExpenseType.Name": current["ExpenseType.Name"],
        "ExpenseType.Code": current["ExpenseType.Code"],
      } as OptionsGroupsTypes["options"][0];

      if (group) {
        group.options.push(option);
      } else {
        previous.push({
          label: current.Group,
          options: [option],
        });
      }

      return previous;
    }, [] as OptionsGroupsTypes[]);

    const orderedResult = expenseCategoryOrderData.order
      .map((group) => result.find((groupItem) => groupItem.label === group))
      .filter(Boolean) as OptionsGroupsTypes[];

    return orderedResult;
  }, []);

  const [values, setValues] = useState(() => {
    const values = new Map<
      IExpense["ExpenseType"],
      Omit<IExpense, "_id" | "Frequency" | "Amount"> & {
        Frequency?: Frequency;
        Amount?: number;
      }
    >();

    expenseGroups.forEach((group) => {
      group.options.forEach((expenseType) => {
        const expense = expenses.find(
          (expense) => expense.ExpenseType === expenseType["ExpenseType.Code"]
        );
        if (expense) {
          values.set(expenseType["ExpenseType.Code"], expense);
        } else {
          values.set(expenseType["ExpenseType.Code"], {
            ApplicantId: applicantPartyId,
            ExpenseType: expenseType["ExpenseType.Code"],
            Frequency: "Monthly",
          });
        }
      });
    });

    return values;
  });
  useEffect(() => {
    setValues((values) => {
      expenseGroups.forEach((group) => {
        group.options.forEach((expenseType) => {
          const expense = expenses.find(
            (expense) => expense.ExpenseType === expenseType["ExpenseType.Code"]
          );
          if (expense) {
            values.set(expenseType["ExpenseType.Code"], expense);
          } else {
            values.set(expenseType["ExpenseType.Code"], {
              ApplicantId: applicantPartyId,
              ExpenseType: expenseType["ExpenseType.Code"],
              Frequency: "Monthly",
            });
          }
        });
      });

      return new Map(values);
    });
  }, [expenses, applicantPartyId, expenseGroups]);

  const valuesRef = useRef(values);
  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  const handleChange = useCallback(
    <T extends "amount" | "frequency">(
        expenseType: IExpense["ExpenseType"],
        valueType: T
      ) =>
      (value: T extends "amount" ? number : Frequency) => {
        let expense = values.get(expenseType)!;

        if (!expense.Frequency) {
          expense = { ...expense, Frequency: "Monthly" };
        }

        if (valueType === "amount") {
          expense = { ...expense, Amount: value as number };
        } else {
          expense = { ...expense, Frequency: value as Frequency };
        }

        values.set(expenseType, expense as IExpense);
        setValues(new Map(values));
        const filteredValues = Array.from(values.values()).filter(
          (expense) => !!expense.Amount && !!expense.Frequency
        ) as IExpense[];
        onChange && onChange(filteredValues);
      },
    [values, onChange]
  );

  const handleChangeAllFrequency: ComponentProps<
    typeof FrequencyConverter
  >["onApply"] = useCallback(
    (targetFrequency, isConvertExistingAmount) => {
      setValues((values) => {
        Array.from(values.values()).forEach((expense) => {
          values.set(expense.ExpenseType, {
            ...expense,
            Frequency: targetFrequency,
            Amount:
              isConvertExistingAmount && expense.Amount
                ? frequencyUtils.convertAmountByFrequency({
                    amount: expense.Amount!,
                    currentFrequency: expense.Frequency!,
                    targetFrequency,
                  })
                : expense.Amount,
          });
        });

        const filteredValues = Array.from(values.values()).filter(
          (expense) => !!expense.Amount && !!expense.Frequency
        ) as IExpense[];
        onChange && onChange(filteredValues);

        return new Map(values);
      });
    },
    [onChange]
  );

  if (view !== "grid") return null;

  return (
    <>
      <table className="w-full">
        <thead className="box-content block border-b border-[#DCD5E3] pb-2">
          <tr className="grid grid-cols-[5fr,2fr,4fr] gap-x-[18px]">
            <td>
              <span className="font-helvetica text-[13px] font-bold text-[#7B5C8D]">
                Expense Name
              </span>
            </td>
            <td>
              <span className="font-helvetica text-[13px] font-bold text-[#7B5C8D]">
                Amount
              </span>
            </td>
            <td>
              <FrequencyConverter
                onApply={handleChangeAllFrequency}
                currentData={values}
              />
            </td>
          </tr>
        </thead>
        <tbody className="relative block max-h-[60vh] w-[calc(100%+12px)] overflow-y-auto pr-3">
          {expenseGroups.map((group) => (
            <Fragment key={group.label}>
              <tr className="sticky top-0 z-[9] mt-[16px] grid grid-cols-[5fr,2fr,4fr] gap-x-[18px] bg-[#F4F1F7] py-1.5 first:mt-[15px]">
                <td className="col-span-full block h-max p-0">
                  <span className="font-helvetica text-[13px] font-bold  text-[#7B5C8D]">
                    {group.label}
                  </span>
                </td>
              </tr>
              {group.options.map((expenseType, index) => (
                <tr
                  key={expenseType["ExpenseType.Code"]}
                  className={twMerge(
                    "grid grid-cols-[5fr,2fr,4fr] items-center gap-x-[18px] bg-white py-2",
                    index === 0 ? "mt-[7px] rounded-t-md" : "mt-0.5",
                    index === group.options.length - 1 ? "rounded-b-md" : ""
                  )}
                >
                  <td>
                    <div className="pl-4">
                      <span className="inline-block font-helvetica text-[13px] text-[#7B5C8D]">
                        {expenseType["ExpenseType.Name"]}
                      </span>
                    </div>
                  </td>
                  <td>
                    <InputNumber
                      id={expenseType["ExpenseType.Code"]}
                      withIcon={<Dollar />}
                      value={
                        values.get(expenseType["ExpenseType.Code"])?.Amount
                      }
                      onChange={handleChange(
                        expenseType["ExpenseType.Code"],
                        "amount"
                      )}
                      className="max-h-[25px] text-right text-[13px]"
                      autonumeric
                    />
                  </td>
                  <td>
                    <div className="flex items-center justify-start pr-4">
                      {[...frequencyUtils.frequencies]
                        .sort((a, b) => b.year - a.year)
                        .map((frequency) => (
                          <label
                            key={frequency.code}
                            htmlFor={`${expenseType["ExpenseType.Code"]}-${frequency.code}`}
                            className={twMerge(
                              "ml-[5px] block h-[25px] w-[25px] cursor-pointer rounded-[3px] border-[1px] text-center text-[11px] font-bold leading-[25px] first:ml-0",
                              frequency.code ===
                                values.get(expenseType["ExpenseType.Code"])
                                  ?.Frequency
                                ? "border-[#907AA0] bg-gradient-to-b from-[#907AA0] to-[#A491B0] text-white"
                                : "border-[#C2B6CB] bg-gradient-to-t from-[#F7EFFA] to-white text-[#7B5C8D]"
                            )}
                          >
                            <input
                              readOnly
                              type="radio"
                              id={`${expenseType["ExpenseType.Code"]}-${frequency.code}`}
                              name={`${expenseType["ExpenseType.Code"]}-frequency`}
                              checked={
                                frequency.code ===
                                values.get(expenseType["ExpenseType.Code"])
                                  ?.Frequency
                              }
                              onChange={() =>
                                handleChange(
                                  expenseType["ExpenseType.Code"],
                                  "frequency"
                                )(frequency.code)
                              }
                              className="hidden"
                            />
                            {frequency.name[0]}
                          </label>
                        ))}
                    </div>
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
};

const Header: FC<{
  onImportButtonClick?: () => void;
  onClose?: () => void;
  onViewChange: (view: ExpenseView) => void;
  view: ExpenseView;
}> = ({ onImportButtonClick, onClose, onViewChange, view }) => {
  return (
    <>
      <div className="ml-[14.5px] flex w-full items-center">
        <input
          type="radio"
          name="view"
          id="grid"
          checked={view === "grid"}
          onChange={() => onViewChange("grid")}
          className="hidden"
        />
        <label
          htmlFor="grid"
          className={twMerge(
            "flex max-h-[30px] cursor-pointer items-center rounded-l-[3px] bg-gradient-to-b px-2 py-1.5 text-[13px] font-bold",
            view === "grid"
              ? "from-[#907AA0] to-[#A491B0] text-white"
              : "border-[1px] border-r-0 border-[#C2B6CB] from-[#F7EFFA] to-[#FFFFFF] text-[#7A5C8D]"
          )}
        >
          <Grid
            width={14}
            height={14}
            className="mr-1 fill-current"
            fill="current"
          />
          Grid View
        </label>
        <input
          type="radio"
          name="view"
          id="list"
          checked={view === "list"}
          onChange={() => onViewChange("list")}
          className="hidden"
        />
        <label
          htmlFor="list"
          className={twMerge(
            "flex max-h-[30px] cursor-pointer items-center rounded-r-[3px] bg-gradient-to-b from-[#907AA0] to-[#A491B0] px-2 py-1.5 text-[13px] font-bold text-white",
            view === "list"
              ? "from-[#907AA0] to-[#A491B0] text-white"
              : "border-[1px] border-l-0 border-[#C2B6CB] from-[#F7EFFA] to-[#FFFFFF] text-[#7A5C8D]"
          )}
        >
          <List
            width={14}
            height={14}
            className="mr-1 fill-current"
            fill="current"
          />
          List View
        </label>
        <Button
          size="medium"
          withIcon={<Spreadsheet />}
          onClick={onImportButtonClick}
          className="ml-auto w-max bg-[#43357F] desktop:text-sm"
        >
          Import Illion Data
        </Button>
      </div>
      <button className="ml-[14.5px]" onClick={onClose}>
        <Close />
      </button>
    </>
  );
};

const Expenses: FC<{
  applicantPartyId: IModelId;
  view: ExpenseView;
  onChangeView: (view: ExpenseView) => void;
  onClose: () => void;
  onImportButtonClick?: () => void;
}> = ({
  applicantPartyId,
  onClose,
  onImportButtonClick,
  view,
  onChangeView,
}) => {
  const [
    expenses,
    addEventListener,
    removeEventsListener,
    hasKiwisaver,
    setFinancial,
  ] = useStore(
    useCallback(
      (state) => [
        state.getFinancial(applicantPartyId, "Expense"),
        state.addEventListener,
        state.removeEventListener,
        state
          .getFinancial(applicantPartyId, "Income")
          .find((income) => income.Attributes.get("Kiwisaver")?.value),
        state.setFinancial,
      ],
      [applicantPartyId]
    ),
    (oldState, newState) => oldState[0] === newState[0]
  );
  const gridExpenses = useRef<IExpense[]>(expenses);
  const groupedExpenses = useRef(
    new Map<IExpense["ExpenseType"], IExpense[]>()
  );

  const [warnedKiwisaver, setWarnedKiwisaver] = useState(false);
  const [warnExpenseTypeDuplicate, setWarnExpenseTypeDuplicate] =
    useState(false);

  const warnKiwisaver = useCallback(
    (event: AddingFinancialEvent<"Expense">) => {
      if (event.type !== "Expense") return;

      if (event.financial.ExpenseType !== "Kiwisaver") return;

      setWarnedKiwisaver(true);
      return {
        ...event.financial,
        ExpenseType: "",
      };
    },
    []
  );

  useEffect(() => {
    if (!hasKiwisaver) return;

    addEventListener("addingfinancial", warnKiwisaver as any);

    return () => {
      removeEventsListener("addingfinancial", warnKiwisaver as any);
    };
  }, [addEventListener, hasKiwisaver, removeEventsListener, warnKiwisaver]);

  const handleViewChange = useCallback(
    (view: ExpenseView) => {
      if (view === "list") {
        onChangeView(view);
        setFinancial(applicantPartyId, {
          type: "Expense",
          payload: gridExpenses.current,
        });
        return;
      }

      let hasDuplicateExpenseType = false;
      groupedExpenses.current.clear();
      expenses.forEach((expense) => {
        const groupedExpense: IExpense[] =
          groupedExpenses.current.get(expense.ExpenseType) || [];
        groupedExpense.push(expense);
        groupedExpenses.current.set(expense.ExpenseType, groupedExpense);
        hasDuplicateExpenseType =
          hasDuplicateExpenseType || groupedExpense.length > 1;
      });

      if (hasDuplicateExpenseType) {
        setWarnExpenseTypeDuplicate(true);
        return;
      }

      gridExpenses.current = expenses;
      onChangeView(view);
    },
    [expenses, applicantPartyId, setFinancial, onChangeView]
  );

  const mergeDuplicateExpenseType = () => {
    const expenses: IExpense[] = [];

    Array.from(groupedExpenses.current.values()).forEach((groupedExpense) => {
      if (groupedExpense.length < 1) return;

      if (groupedExpense.length < 2) {
        expenses.push(groupedExpense[0]);
        return;
      }

      // find the highest frequency expense, other expenses amount will be converted to this frequency amount
      let highestFrequencyExpense = groupedExpense.reduce(
        (acc, currentExpense) => {
          if (
            frequencyUtils.frequencies.find(
              (frequency) => frequency.code === currentExpense.Frequency
            )!.year >
            frequencyUtils.frequencies.find(
              (frequency) => frequency.code === acc.Frequency
            )!.year
          ) {
            return currentExpense;
          }

          return acc;
        }
      );

      groupedExpense.forEach((expense) => {
        if (expense._id === highestFrequencyExpense._id) return;

        highestFrequencyExpense = {
          ...highestFrequencyExpense,
          Amount:
            highestFrequencyExpense.Amount +
            frequencyUtils.convertAmountByFrequency({
              amount: expense.Amount,
              currentFrequency: expense.Frequency,
              targetFrequency: highestFrequencyExpense.Frequency,
            }),
        };
      });

      expenses.push(highestFrequencyExpense);
    });

    gridExpenses.current = expenses;
    setFinancial(applicantPartyId, {
      type: "Expense",
      payload: gridExpenses.current,
    });
  };

  return (
    <>
      <Modal
        open={warnExpenseTypeDuplicate}
        backdropAnimation={{
          initial: { backgroundColor: "#00000000" },
          animate: { backgroundColor: "#00000055" },
          exit: { backgroundColor: "#00000000" },
        }}
        containerClassName="mt-[55px] rounded-[6px] max-w-[483px] px-[57px]"
      >
        <InfoRounded
          width={70}
          height={70}
          fill="#579BCB"
          className="mx-auto mt-1"
        />
        <p className="mt-2 text-center text-[21px] font-bold text-[#7B5C8D]">
          Your list contains multiple expenses of the same type
        </p>
        <p className="mt-1 text-center text-[13px] text-[#7B5C8D]">
          These will be converted to a common frequency and combined into a
          single entry in the grid.
        </p>
        <div className="mt-7 flex items-center justify-center">
          <Button
            onClick={() => setWarnExpenseTypeDuplicate(false)}
            className="max-w-[157px] border border-[#7A5C8D] bg-transparent py-2.5 text-xs font-bold text-[#7A5C8D]"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              mergeDuplicateExpenseType();
              setWarnExpenseTypeDuplicate(false);
              onChangeView("grid");
            }}
            className="ml-2 max-w-[157px] border border-[#7A5C8D] py-2.5  text-xs font-bold"
          >
            Confirm and proceed
          </Button>
        </div>
      </Modal>
      <FinancialDetails
        header="Expenses"
        onClose={onClose}
        nodeOnHeader={
          <Header
            onImportButtonClick={onImportButtonClick}
            onClose={onClose}
            onViewChange={handleViewChange}
            view={view}
          />
        }
        footer={
          view === "grid" ? (
            <div className="flex items-center justify-center">
              <Button
                onClick={onClose}
                className="max-w-[157px] border border-[#7A5C8D] bg-transparent py-2.5 text-xs font-bold text-[#7A5C8D]"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setFinancial(applicantPartyId, {
                    type: "Expense",
                    payload: gridExpenses.current,
                  });
                  onClose();
                }}
                className="ml-2 max-w-[157px] border border-[#7A5C8D] py-2.5  text-xs font-bold"
              >
                Save and Close
              </Button>
            </div>
          ) : null
        }
      >
        {!warnedKiwisaver && (
          <>
            <ExpensesListView
              applicantPartyId={applicantPartyId}
              expenses={expenses}
              view={view}
            />
            <ExpensesGridView
              expenses={expenses}
              applicantPartyId={applicantPartyId}
              onChange={(expenses) => {
                gridExpenses.current = expenses;
                if (view === "list") {
                  setFinancial(applicantPartyId, {
                    type: "Expense",
                    payload: expenses,
                  });
                }
              }}
              view={view}
            />
          </>
        )}
        <AnimatePresence>
          {warnedKiwisaver && (
            <motion.div
              variants={{
                unmounted: {
                  height: 0,
                  opacity: 0,
                },
                mounted: {
                  height: "auto",
                  opacity: 1,
                },
              }}
              initial="unmounted"
              animate="mounted"
              exit="unmounted"
              transition={{ duration: 0.2, ease: "easeInOut" }}
              className="overflow-hidden"
            >
              <div className="flex flex-col items-center bg-white py-12">
                <figure>
                  <InfoRounded fill="#579BCB" width={54} height={54} />
                </figure>
                <p className="mt-[28px] font-helvetica text-[13px] text-[#7B5C8D] desktop:text-sm">
                  Kiwisaver has already been entered in Income
                </p>
                <Button
                  size="medium"
                  className="mt-[28px] w-max px-16"
                  onClick={() => setWarnedKiwisaver(false)}
                >
                  Okay
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </FinancialDetails>
    </>
  );
};

export default Expenses;
