import { SectionLabel } from "../components/label";
import { CircleBar } from "../components/circlebar";
import useStore from "../store";
import { useCallback } from "react";

const ResultHeader = () => {
  const accuracy = useStore(
    useCallback((state) => state.Report.Accuracy * 100, [])
  );

  return (
    <header className="flex items-center justify-between">
      <SectionLabel className="text-base desktop:text-lg">
        Search Result
      </SectionLabel>
      <div className="flex items-end space-x-3">
        <CircleBar value={accuracy} />
        <SectionLabel className="mb-1.5 text-xs leading-[0.7em] desktop:text-sm desktop:leading-[0.7em]">
          Accuracy Rating
        </SectionLabel>
      </div>
    </header>
  );
};

export default ResultHeader;
